import { useMemo } from "react";
import dayjs from "dayjs";
import { useTranslation } from "../context/translation";

export function useDateFormatter() {
  const { i18n } = useTranslation();

  return useMemo(() => {
    const formatDate = (
      dateTime: string,
      withClock = false,
      clockWithSeconds = false,
      suffix: string = '',
    ) => {
      const locale = i18n.substring(0, 2);

      let format = 'D.M.YYYY'
      switch (locale) {
        case 'de':
          format = 'DD.MM.YYYY';
          break;
      }

      return `${dayjs(dateTime).locale(locale).format(format).toString()} ${suffix}`;
    };

    const getReadableMonth = (
      dateTime: string
    ) => {
      const date = new Date(dateTime);

      return `${date.toLocaleString(i18n, {
        month: "long",
      })}`;
    };

    const getDaysUntilNextMonth = () => {
      const date1 = new Date();
      const date2 = new Date();

      date2.setDate(1);
      date2.setMonth(date2.getMonth() + 1);
      const diffTime = Math.abs(date2.getTime() - date1.getTime());

      return Math.floor(diffTime / (1000 * 60 * 60 * 24));
    };

    return {
      formatDate,
      getReadableMonth,
      getDaysUntilNextMonth,
    };
  }, [i18n]);
}
