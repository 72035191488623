import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { ApiClass, ApiFactory } from "./types";

import auth from "./auth";
import cms, { Cms } from "./cms";
import check, { Check } from "./check";
import partner, { Partner } from "./partner";

import config from "../config";
import advancedFormat from "dayjs/plugin/advancedFormat";
import translation, { Translation } from "./translation";

dayjs.extend(advancedFormat);

type Api = {
  authenticated: boolean;
  decryptAsset: (value: any) => Promise<any>;
  [key: string]: ApiClass<any>;
};

type AuthenticationType = {
  authenticated: boolean;
  token: string | null;
  expires: number;
}

const defaultApiContext: Api = {
  authenticated: false,
  decryptAsset: async () => {},
};

const ApiContext = createContext<Api>(defaultApiContext);
const DefaultAuthObj: AuthenticationType = {
  authenticated: false,
  token: null,
  expires: -1
};
export default function ApiContextProvider({ children, i18n }: PropsWithChildren<{i18n: string}>) {
  const [authObj, setAuthObj] = useState<AuthenticationType>(DefaultAuthObj);

  const hasNetworkError = () => {
  };

  const clearNetworkError = () => {
  };

  function buildApi<ApiType>(
    factory: ApiFactory<ApiType>
  ): ApiClass<ApiType> {
    return factory(config.apiHost, authObj.token, hasNetworkError, i18n);
  }

  const authApi = buildApi(auth);

  const getLocalAuthentication = (): AuthenticationType => {
    const dataStr = localStorage.getItem("mam:authenticate");
    if (dataStr) {
      const loadedObj: AuthenticationType = JSON.parse(dataStr);
      if ((loadedObj.expires - 600) > parseInt(dayjs().format("X"))) {
        return loadedObj;
      }
    }
    return DefaultAuthObj;
  };

  const getFreshAuthentication = async (): Promise<AuthenticationType> => {
    const tokenResponse = await authApi.authenticate();
    if (tokenResponse) {
      return {
        authenticated: true,
        token: tokenResponse.access_token,
        expires: parseInt(dayjs().format("X")) + parseInt(tokenResponse.expires_in)
      };
    }
    return DefaultAuthObj;
  };

  useEffect(() => {
    let newAuthObj: AuthenticationType = DefaultAuthObj;
    if (!authObj.authenticated) {
      newAuthObj = getLocalAuthentication();
    }
    if (!newAuthObj.authenticated) {
      getFreshAuthentication().then(setAuthObj);
    } else {
      setAuthObj(newAuthObj);
    }
  }, []);

  useEffect(() => {
    if (authObj) {
      localStorage.setItem("mam:authenticate", JSON.stringify(authObj));
    }
  }, [authObj]);

  const decryptAsset = async (value: any) => {
    try {
      const response = await fetch(
        new URL(`/asset/decrypt`, config.apiHost),
        {
          method: "POST",
          body: JSON.stringify({value}),
          mode: "cors"
        }
      );
      return await response.json();
    } catch (e) {
      return null;
    }
  };

  let apis = {
    authenticated: Boolean(authObj.authenticated) && Boolean(i18n),
    decryptAsset,
    translation: buildApi<Translation>(translation),
  };
  if (authObj.token) {
    apis = {
      ...apis, ...{
        auth: authApi,
        check: buildApi<Check>(check),
        cms: buildApi<Cms>(cms),
        partner: buildApi<Partner>(partner)
      }
    };
  }
  return (
    <ApiContext.Provider value={apis}>
      {children}
    </ApiContext.Provider>
  );
}

export const useApi = () => {
  return useContext(ApiContext);
};
