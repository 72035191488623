import React, { useEffect, useLayoutEffect, useReducer, useState } from "react";

import { useDataQuery } from "../../utils/data-query";
import { useTranslation } from "../../context/translation";
import { DirectSelectEvent } from "./PartnerChipMenu";
import { getUrlVarAndValue } from "./LastVisited";
import { PageBlockProps, PageListItem } from "../index";
import { CategorySelectEvent } from "./CategorySelector";
import { updateFrameSize, useFormatter } from "../../utils/static";
import { useDidMountEffect } from "../../utils/mount";
import dayjs from "dayjs";

type PartnerItem = {
  id: string;
  name: string;
  network: any;
  partner_id: string;
  unique_name: string;
  has_free_shipping: boolean;
  has_reduction: boolean;
  has_campaign: boolean;
  additional_wording?: boolean;
  partner_logo?: { resolved_url: string };
  min_refund?: {
    type: "campaign" | "free_shipping" | "reduction";
    point_type: "factor" | "static";
    point_value: number;
  }
};

type OrderType = "asc" | "desc";

type ParamType = {
  limit?: number;
  offset?: number;
  sort?: string;
  order?: OrderType;
  filter?: { [key: string]: string };
};

type PartnerFilterEntry = { key: string; value: string };

interface PartnerListProps extends PageBlockProps {
  allowFilter: boolean;
  allowLoadMore: boolean;
  bestPartner?: boolean;
  disableNormalLoad?: boolean;
  parentLoading?: boolean;
  partnerPage?: PageListItem;
  result?: {
    max_length?: number;
    results: {
      partner: Array<PartnerItem>;
    }
  };
  filter?: Array<PartnerFilterEntry>;
  onFilterChange?: (filter: { sort?: string, order?: "asc" | "desc" }) => void;
  onLoadMore?: () => void;
};

const defaultParams: ParamType = { limit: 15, offset: 0, sort: "name", order: "asc" };

const buildFilter = (filter?: Array<PartnerFilterEntry>, prevFilter?: any) => {
  const filterObj: any = { ...prevFilter };
  for (const entry of filter ?? []) {
    if (typeof entry["key"] !== "undefined" && typeof entry["value"] !== "undefined") {
      filterObj[entry["key"]] = entry["value"];
    }
  }
  return filterObj;
};

function paramsReducer(state: ParamType, action: { type: string, payload?: any }): ParamType {
  switch (action.type) {
    case "bestPartner":
      const now = dayjs().format('MM-YYYY');
      return {
        ...state,
        filter: buildFilter([{ key: "keyWords", value: `recommendations-${now}` }]),
        sort: action.payload ? "loyaltyPoints" : defaultParams.sort,
        order: action.payload ? "desc" : defaultParams.order
      };
    case "filter":
      return {
        ...state,
        offset: 0,
        filter: buildFilter(action.payload)
      };
    case "filter|bestPartner":
      return {
        ...state,
        filter: buildFilter(action.payload.filter),
        sort: action.payload.bestPartner ? "loyaltyPoints" : defaultParams.sort,
        order: action.payload.bestPartner ? "desc" : defaultParams.order
      };
    case "update":
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

export default function PartnerList({
                                      allowFilter = false,
                                      allowLoadMore = false,
                                      bestPartner = false,
                                      parentLoading = false,
                                      result,
                                      disableNormalLoad = false,
                                      partnerPage,
                                      onFilterChange = () => {
                                      },
                                      onLoadMore = () => {
                                      },
                                      filter,
                                      preview = false
                                    }: PartnerListProps) {
  const { translate } = useTranslation();
  const { formatNumber } = useFormatter();

  const { loading, data, runQuery } = useDataQuery();

  const [previewMode, setPreviewMode] = useState(false);
  const [params, paramDispatch] = useReducer(paramsReducer, defaultParams);

  useEffect(() => {
    if (typeof filter !== "undefined" && typeof bestPartner !== "undefined") {
      paramDispatch({ type: "filter|bestPartner", payload: { filter, bestPartner } });
    } else {
      const now = dayjs().format('MM-YYYY');
      paramDispatch({ type: "bestPartner", payload: bestPartner });
      document.dispatchEvent(new CustomEvent<DirectSelectEvent>('reverseCampaignSelect', {detail: {type: `recommendations-${now}`, preview: false}}));
    }
  }, [filter, bestPartner]);

  useEffect(() => {
    const onDirectSelect = (event: CustomEvent<DirectSelectEvent>) => {
      if (event.detail.type !== "all") {
        paramDispatch({ type: "filter", payload: [{ key: "keyWords", value: event.detail.type }] });
      } else {
        paramDispatch({ type: "filter", payload: [{ key: "keyWords", value: undefined }] });
      }
      if (event.detail.preview) {
        updateSort('name', 'asc');
      }
      setPreviewMode(event.detail.preview ?? false);
    };

    const onCategorySelect = (event: CustomEvent<CategorySelectEvent>) => {
      paramDispatch({ type: "filter", payload: [{ key: "categories", value: event.detail.category }] });
    };

    document.addEventListener("campaignSelect", onDirectSelect as EventListener);
    document.addEventListener("categorySelect", onCategorySelect as EventListener);
    return () => {
      document.removeEventListener("campaignSelect", onDirectSelect as EventListener);
      document.removeEventListener("categorySelect", onCategorySelect as EventListener);
    };
  }, []);

  useDidMountEffect(() => {
    if (params) {
      if ((preview || typeof result === "undefined") && !disableNormalLoad) {
        runQuery({
          api: "partner",
          call: "getPartnerList",
          args: Object.values(params)
        }, true);
      }
      onFilterChange(params);
    }
  }, [params]);

  useLayoutEffect(() => {
    updateFrameSize();
  }, [data]);

  const renderItem = (item: PartnerItem, index: number) => {
    const urlParams = getUrlVarAndValue(partnerPage?.slug ?? "", item);
    let url = "";
    if (urlParams) {
      url = partnerPage?.slug.replace(urlParams.variable, urlParams.value) ?? "";
    }

    const aProps: {href?: string} = {href: undefined};
    if (!previewMode) {
      aProps.href = url;
    }

    return (
      <div key={index} className="button_container col-xs-6 col-sm-4" data-role="none">
        <a {...aProps} title={translate("mam.miles.earn", item.name)}
           className="button partner"
           data-role="none" rel="internal">
          <div className="logo">
            {!previewMode && (
              <div className="icon">
                {item.has_reduction && (
                  <span className="mam-ico92"></span>
                )}
                {item.has_free_shipping && (
                  <span className="mam-ico99"></span>
                )}
              </div>
            )}
            <img
              src={item.partner_logo?.resolved_url}
              alt={item.name} />
          </div>
          {!previewMode && (
            <div className={`miles ${item.has_campaign ? `campaign` : ``}`}>
              {item.additional_wording ?
                translate(`mam.miles.additional_wording.${item.min_refund?.point_type ?? "factor"}`, formatNumber(item.min_refund?.point_value ?? 1))
                : translate(`mam.miles.${item.min_refund?.point_type ?? "factor"}`, formatNumber(item.min_refund?.point_value ?? 1))}
            </div>
          )}
        </a>
      </div>
    );
  };

  const updateSort = (col: string, order: OrderType = "asc") => {
    paramDispatch({ type: "update", payload: { sort: col, order, offset: 0 } });

  };

  const loadMore = () => {
    onLoadMore();
    paramDispatch({ type: "update", payload: { offset: (params?.offset ?? 0) + (params?.limit ?? 15) } });
  };

  const maxLength = result?.max_length ?? data?.max_length ?? 0;
  const itemArray = result?.results?.partner ?? data?.data ?? [];

  return (
    <section className="mamcom_component partner_listing_container overflow_visible">
      <div className="col-sm-10 offset-sm-1 partner-view-list">
        <div className="sort row">
          {(allowFilter && !previewMode) && (
            <div className="select">
              <span className="mam-ico25 icon"></span><span className="mam-ico24 icon"></span>
              <span className="text">{translate(`mam.blocks.partner.list.sort.title`)}</span>
              <div className="dropdown">
                <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown"
                        aria-expanded="false">{translate(`mam.blocks.partner.list.sort.${params?.sort ?? "name"}.${params?.order ?? "asc"}`)}
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <button className="dropdown-item active"
                            onClick={() => updateSort("loyaltyPoints")}>{translate(`mam.blocks.partner.list.sort.loyaltyPoints.asc`)}
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item"
                            onClick={() => updateSort("loyaltyPoints", "desc")}>{translate(`mam.blocks.partner.list.sort.loyaltyPoints.desc`)}
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item"
                            onClick={() => updateSort("name")}>{translate(`mam.blocks.partner.list.sort.name.asc`)}</button>
                  </li>
                  <li>
                    <button className="dropdown-item"
                            onClick={() => updateSort("name", "desc")}>{translate(`mam.blocks.partner.list.sort.name.desc`)}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
          <div className="result">
            <span className="counter">{translate("mam.blocks.partner.list.results", maxLength)}</span>
          </div>
        </div>
        <div className="logos row">
          {itemArray.map(renderItem)}
          {(parentLoading || loading) ? (
            <section className="mamcom_component mam_loadingspinner_container">
              <div className="row">
                <div className="backlink col-xs-12">
                  <div className="loadingSpinner">
                    <svg className="loadingSpinner_circleWrapper" viewBox="0 0 50 50">
                      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                    </svg>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            (allowLoadMore && (data?.has_next || disableNormalLoad)) && (
              <div className="text-center clear-both">
                <button className="button text-btn" onClick={loadMore}>
                  {translate("mam.blocks.partner.list.load_more")}
                </button>
              </div>
            )
          )}
        </div>
        <div className="footnote row">
          <div className="campaign"><span
            className="mam-ico92 icon"></span> {translate("mam.blocks.partner.list.voucher_and_sale")}</div>
          <div className="freeshipping"><span
            className="mam-ico99 icon"></span> {translate("mam.blocks.partner.list.free_shipping")}</div>
        </div>
      </div>
      <div className="clear-both"></div>
    </section>
  );
}
