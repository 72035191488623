import { useTranslation } from "../../context/translation";
import { updateFrameSize } from "../../utils/static";
import { useCallback, useLayoutEffect } from "react";

type ConditionProps = {
  title?: string;
  items?: Array<any>;
  blockIndex?: number;
}

export default function Conditions({title, items, blockIndex}: ConditionProps) {
  const {translate} = useTranslation();

  const updateHeight = useCallback(() => setTimeout(() => {
    updateFrameSize();
  }, 500), []);

  const renderItem = (item: any, index: number) => (
    <div key={`accordion-${blockIndex}-${index}`} className="accordion-item">
      <div className="accordion-header">
        <button className={`accordion-button ${index !== 0 ? 'collapsed' : ''}`} onClick={updateHeight} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${blockIndex}_${index}`}
                aria-expanded={index === 0 ? 'true' : 'false'} aria-controls={`collapse_${blockIndex}_${index}`}>
          <h4 className="accordion-boxheadline" dangerouslySetInnerHTML={{__html: item?.title ?? ''}} />
          <span className="mam-ico6 icon"></span>
        </button>
      </div>
      <div id={`collapse_${blockIndex}_${index}`} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}>
        <div className="accordion-body" dangerouslySetInnerHTML={{__html: item?.text ?? ''}}></div>
      </div>
    </div>
  );

  return (
    <section className="mamcom_component mam_accordion">
      <div className="row">
        <div className="accordion accordion-flush col-sm-8 offset-sm-2">
          <header className="accordion-header">
            <div className="heading" dangerouslySetInnerHTML={{ __html: title ?? `<h3>${translate('mam.blocks.partner.conditions.title')}</h3>`}}></div>
          </header>
          {items?.map(renderItem)}
        </div>
      </div>
    </section>
  )
}