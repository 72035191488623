import { useTranslation } from "../../context/translation";
import { useImageBuilder } from "../../utils/image";
import { useLinkBuilder } from "../../utils/link";
import { stripHtml } from "../../utils/static";


type SpecialCardsProps = {
  title?: string;
  grid?: number;
  items?: Array<any>;
};

export default function SpecialCards({title, grid, items}: SpecialCardsProps) {
  const {translate} = useTranslation();
  const {buildImageUrl} = useImageBuilder();
  const {buildLinkByPage} = useLinkBuilder();

  const renderItem = (item: any, index: number) => {
    const content = () => (
      <>
        {item.title && (<div dangerouslySetInnerHTML={{__html: item.title }}/>)}
        {item.image && (<img src={buildImageUrl(item.image)} alt={stripHtml(item.title)} className="img-fluid" />)}
        {item.text && (<p style={{paddingTop: 10}} dangerouslySetInnerHTML={{__html: item.text}} />)}
      </>
    );

    if (item.link) {
      return (
        <a key={`special-card-${index}`} className="category_teaser col" title={stripHtml(item.title)}
           href={buildLinkByPage(item.link)}>
          {content()}
        </a>
      );
    }

    return (
      <div key={`special-card-${index}`} className="category_teaser col">
        {content()}
      </div>
    );
  }

  const renderLayout = () => {
    if (grid === 1) {
      return (
        <div className="row col-sm-10 offset-sm-1 row-cols-1">
          {items?.map(renderItem)}
        </div>
      );
    } else {
      return (
        <div className="row col-sm-10 offset-sm-1 row-cols-1 row-cols-sm-2">
          {items?.map(renderItem)}
        </div>
      )
    }
  };

  return (
    <section className="mamcom_component overflow_visible offer_special_container">
      <div className="row">
        <div className="col-sm-10 offset-sm-1 offer_special">
          <header className="container_header">
            <div className="heading"
                 dangerouslySetInnerHTML={{ __html: title ?? `<h2>${translate('mam.blocks.partner.special_cards.title')}</h2>` }} />
          </header>
        </div>
        {renderLayout()}
      </div>
    </section>
  )

}