import { useTranslation } from "../../context/translation";
import { useLinkBuilder } from "../../utils/link";
import { stripHtml } from "../../utils/static";
import { useDateFormatter } from "../../utils/date";


type AdditionalOffersProps = {
  title?: string;
  subTitle?: string;
  items?: Array<any>;
}

export default function AdditionalOffers({title, subTitle, items}: AdditionalOffersProps) {
  const {translate} = useTranslation();
  const {buildLinkByPage} = useLinkBuilder();
  const {getReadableMonth} = useDateFormatter();

  const renderItem = (item: any, index: number) => (
    <div key={`card-${index}`} className="campaign_card col">
      <a className="campaign_card_link" href={item.link ? buildLinkByPage(item.link) : '#'} rel="internal">
        <div className="campaign_card_content">
          <div className="campaign_text_wrapper">
            <span className="campaign_text" dangerouslySetInnerHTML={{__html: item?.text ?? ''}} title={stripHtml(item?.text ?? '')}/>
          </div>
          <span className="mam-ico24"></span>
        </div>
      </a>
    </div>
  );

  const month = getReadableMonth(new Date().toISOString());

  return (
    <section className="mamcom_component overflow_visible mam_campaign_card_group_container">
      <div className="row">
        <div className="col-sm-10 offset-sm-1">
          <header className="container_header mb-4">
            <div className="heading">
              <h2 dangerouslySetInnerHTML={{ __html: title ?? translate("mam.blocks.partner.additional_offers.title") }} />
            </div>
            <div className="heading">
              <h3 dangerouslySetInnerHTML={{ __html: subTitle ?? translate("mam.blocks.partner.additional_offers.sub_title", month)}} />
            </div>
          </header>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            {items?.map(renderItem)}
          </div>
        </div>
      </div>
    </section>
  )
}