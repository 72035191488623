import Headline from "./content-blocks/Headline";
import HomeSlider from "./content-blocks/HomeSlider";
import Text from "./content-blocks/Text";
import PartnerList from "./content-blocks/PartnerList";
import PartnerHeadBlock from "./content-blocks/PartnerHeadBlock";
import PartnerSearch from "./content-blocks/PartnerSearch";
import LastVisited from "./content-blocks/LastVisited";
import TrackLastVisited from "./content-blocks/TrackLastVisited";
import PartnerChipMenu from "./content-blocks/PartnerChipMenu";
import CardGroup from "./content-blocks/CardGroup";
import PartnerCategories from "./content-blocks/PartnerCategories";
import SearchResultPage from "./SearchResultPage";
import HowTo from "./content-blocks/HowTo";
import SpecialCards from "./content-blocks/SpecialCards";
import PartnerExitOverlay from "./content-blocks/PartnerExitOverlay";
import AdditionalOffers from "./content-blocks/AdditionalOffers";
import Conditions from "./content-blocks/Conditions";

export type Response = {
  error: boolean;
  status: number;
}

export type TemplateFormType = {
  type: string;
  name: string;
  label: string;
  required: boolean;
};

export type ParsedContent = {
  mapping?: {
    api?: string;
    call?: string;
    args?: Array<{[key: string]: string}>;
  };
  form?: Array<TemplateFormType>;
  props?: {[key: string]: any};
};

export type TemplateBlock<T> = {
  id: string;
  index?: number;
  type: string;
  block_name: string;
  content?: T;
};

export type TemplateConfig = {
  config: {
    defaultBlock: string;
    blocks: Array<TemplateBlock<ParsedContent>>;
  }
};

export type PageBlockProps = {
  preview?: boolean;
}

export type PageListItem = {
  id: string;
  title: string;
  slug: string;
  online: boolean;
  locale: string;
  last_edited: string;
  home_page: boolean;
  has_bonded_data?: boolean;
  bind_value?: any;
};

export interface PageItem<T = any> extends PageListItem {
  has_parent: boolean;
  parent?: PageListItem | null;
  blocks: Array<TemplateBlock<string>>;
  publish_date: string;
  bonded_data?: T
}

export interface PageItemResponse<T = any> extends PageItem<T>, Response {}

const Components: any = {
  "cms.form.block.headline": Headline,
  "cms.form.block.slider": HomeSlider,
  "cms.form.block.text": Text,
  "cms.form.block.search": PartnerSearch,
  "cms.form.block.search_result": SearchResultPage,
  "cms.form.block.chip_menu": PartnerChipMenu,
  "cms.form.block.categories": PartnerCategories,
  "cms.form.block.partner": PartnerList,
  "cms.form.block.partner_head_block": PartnerHeadBlock,
  "cms.form.block.partner_exit_overlay": PartnerExitOverlay,
  "cms.form.block.partner_how_to": HowTo,
  "cms.form.block.last_visited": LastVisited,
  "cms.form.block.track_visit": TrackLastVisited,
  "cms.form.block.cards": CardGroup,
  "cms.form.block.special_cards": SpecialCards,
  "cms.form.block.additional_offer": AdditionalOffers,
  "cms.form.block.conditions": Conditions,
};

export default Components;
